<template>
<div class="wrapper">
    <div class="topmenu open" id="topMenu">
        <div class="menuinner">
            <div class="container-fluid">
                <div class="row flex-row flex-nowrap">
                    <div class="col-auto">
                        <img src="https://megaoffice.mega-schutting.nl/images/megaoffice_square.png" class="menulogo">
                    </div>
                    <div class="col-auto">
                        <a href="#" class="menuitem" v-on:click="setProductionType(1)" v-bind:class="{ 'active' : (productionFilter.filterType == 1)}"><i class="fas fa-toolbox"></i> Assemblage</a>
                    </div>
                    <div class="col-auto">
                        <a href="#" class="menuitem" v-on:click="setProductionType(2)" v-bind:class="{ 'active' : (productionFilter.filterType == 2)}"><i class="fas fa-tools"></i> Zagen</a>
                    </div>
                    <div class="col-auto">
                        <a href="#" class="menuitem" v-on:click="setProductionType(3)" v-bind:class="{ 'active' : (productionFilter.filterType == 3)}"><i class="fa-solid fa-rotate"></i> Demontage</a>
                    </div>
                    <div class="col d-flex flex-row-reverse me-4 pe-4 mt-3">
                        <button type="button" class="btn text-white btn-square-md" data-bs-toggle="modal" data-bs-target="#filterModal"><i class="fas fa-filter fa-2x"></i></button>
                        <button type="button" class="btn text-white btn-square-md queue_counter" v-on:click="startSendQueue" v-if="countQueue >= 1"><i class="fas fa-cloud-arrow-up fa-2x"></i> <span class="badge badge-light" v-html="countQueue"></span></button>
                    </div>  
                </div>      
            </div>
        </div>
    </div>

    <div class="container-fluid py-4 p-0 p-md-4" v-if="renderComponent">
        <div class="cacheMessage" v-if="productionData.from_cache !=''">
            Let op, verbinding met server verbroken. Data wordt geladen uit cache -<span class="" v-html="productionData.from_cache"></span>-
        </div>

        <div class=" d-none d-md-inline">
            <div class="container-fluid bg-light mb-3 p-2">
                <div class="row">
                    <div class="col-6 col-md-6 col-lg-3 align-self-start">
                    <h5>Status: 
                        <span v-if="productionFilter.filterStatus.statusNew == 1" class="badge bg-primary me-2 col-12 col-md-auto">Nieuw</span>
                        <span v-if="productionFilter.filterStatus.statusInProduction == 1" class="badge bg-primary me-2 col-12 col-md-auto">In productie</span>
                        <span v-if="productionFilter.filterStatus.statusComplete== 1"  class="badge bg-primary me-2 col-12 col-md-auto">Gereed</span>
                        <button class="btn" data-bs-toggle="modal" data-bs-target="#filterModal"><i class="fa-solid fa-pen-to-square"></i></button>
                    </h5> 
                    </div>
                    <div class="col-6 col-md-6 col-lg-3">    
                        <h5>Prioriteit:
                            <span v-if="productionFilter.filterPriority.priorityHigh == 1" class="badge bg-primary me-2 col-12 col-md-auto">Hoog</span>
                            <span v-if="productionFilter.filterPriority.priorityDefault == 1" class="badge bg-primary me-2 col-12 col-md-auto">Standaard</span>
                            <span v-if="productionFilter.filterPriority.priorityLow == 1" class="badge bg-primary me-2 col-12 col-md-auto">Laag</span>
                            <button class="btn" data-bs-toggle="modal" data-bs-target="#filterModal"><i class="fa-solid fa-pen-to-square col-12"></i></button>
                        </h5>
                    </div>
                </div>
            </div>
        </div>
        <table class="table table-striped bg-light" v-if="Object.keys(productionData.productions).length >= 1">
            <thead>
                <tr >
                    <th><span class="d-none d-md-inline-block">Productie nr</span></th>
                    <th>Product</th>
                    <th class="text-center">Aantal</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr class="productionline" v-for="(production, pKey) in filterProductions" :key="production.id">
                        <td class="col-1"><p>{{production.number }}</p></td>
                        <td class="col-7">{{production.description}}<br />
                        <small class="text-muted" style="white-space: pre;">{{ production.comment }}</small></td>
                        <td class="col-3 text-center"><p>{{production.quantity}}</p></td>
                        <td class="col-1">
                            <button v-if="production.production_status == 0"  type="button" class="btn btn-secondary btn-square-md" v-on:click="startProduction(pKey)" ><i class="fas fa-check fa-2x"></i></button>
                            <button v-if="production.production_status == 1"  type="button" class="btn btn-primary btn-square-md" v-on:click="setproductionKey(pKey)" data-bs-toggle="modal" data-bs-target="#productionModal"><i class="fa-solid fa-spinner fa-2x"></i></button>
                            <button v-if="production.production_status == 2"  type="button" class="btn btn-success btn-square-md"><i class="fa-solid fa-check-double fa-2x"></i></button>
                        </td>
                    </tr>
            </tbody>
        </table>
    </div>

<!-- MODAL production   -->
    <div class="modal fade" id="productionModal" aria-hidden="true" aria-labelledby="productionModal" tabindex="-1" v-if="productionKey != ''">
        <div class="modal-dialog modal-xl modal-dialog-centered ">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="productionModal">Productie <span  v-html="productionKey"></span></h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body" v-if="productionData.productions[productionKey] && this.currentProductions[productionKey]">
                    <div class="container-fluid overflow-auto" style="max-height: 250px;">
                        <div class="row">
                            <table class="table bg-light overflow-hidden" >
                                <tbody class="overflow-hidden">
                                    <tr class="row overflow-hidden">
                                        <td class="item-count-nav col-12 col-md-12 col-lg-4 col-xl-3 order-2 order-lg-1" align="center" >    
                                            <button type="button" class="btn btn-sm btn-light" v-on:click="changeProductionQuantityFinish(productionKey, -1)">
                                                <i class="fas fa-minus"></i>
                                            </button>

                                            <input v-model="currentProductions[productionKey].quantity_finish" v-on:change="forceRerender()" class="form-control form-control-lg quantity-input" type="number" step="1.00" />

                                            <button type="button" class="btn btn-sm btn-light" v-on:click="changeProductionQuantityFinish(productionKey, 1)">
                                                <i class="fas fa-plus"></i>
                                            </button>
                                        </td>
                                        <td class="col-12 col-lg-8 fw-bold align-middle order-1 order-lg-2 text-center  mt-0 mt-md-2" v-on:click="setProductionQuantityFinish(productionKey)">
                                            <span v-html="currentProductions[productionKey].quantity"></span> x 
                                            {{productionData.productions[productionKey].description}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <hr class="bg-danger border-3 border-top border-dark">
                    <div class="container-fluid overflow-auto mt-3" style="max-height: 60vh; min-height: 60vh;">
                        <div class="row" >
                            <div class="col-12  col-lg-6 col-xl-4 col-xxl-3 mt-2 ps-0 py-3" v-for="sProduct in currentProductions[productionKey].source_products" :key="sProduct.product_id">
                                <div class="border bg-light p-md-3 h-100 boxscreen-item" v-if="productsData.products && productsData.products['p' + sProduct.product_id]" v-bind:class="{'border-danger': ((sProduct.default) && (sProduct.quantity_finish != (sProduct.quantity * currentProductions[productionKey].quantity_finish)))}">
                                    <div class="item-title mb-3" v-on:click="setSourceQuantityFinish(sProduct)">
                                        <span v-html="(sProduct.quantity * currentProductions[productionKey].quantity_finish)" v-if="sProduct.default"></span> <span v-if="sProduct.default">x </span>
                                        <h6 class="d-inline-block mb-0" v-html="productsData.products['p' + sProduct.product_id].title"></h6>
                                    </div>
                                    <div class="item-img" v-on:click="setSourceQuantityFinish(sProduct)">                        
                                        <img v-bind:src="productsData.products['p' + sProduct.product_id].image" alt="Product afbeelding" class="responsive-fluid w-100 d-none d-lg-inline" />
                                        <button type="button" class="d-none d-lg-inline"  v-if="sProduct.default">
                                            <i class="fas fa-check" v-bind:class="{'text-danger': ((sProduct.default) && (sProduct.quantity_finish != (sProduct.quantity * currentProductions[productionKey].quantity_finish)))}"></i>
                                        </button>
                                    </div>
                                    <div class="mt-3 item-count">
                                        <button type="button" class="btn btn-sm btn-light" v-on:click="changeSourceQuantityFinish(sProduct, -1)">
                                            <i class="fas fa-minus"></i>
                                        </button>

                                        <input v-model="sProduct.quantity_finish" class="form-control form-control-lg quantity-input" type="number" step="1.00"  v-on:change="changeSourceQuantityFinish(sProduct, 0)"  />

                                        <button type="button" class="btn btn-sm btn-light" v-on:click="changeSourceQuantityFinish(sProduct, 1)">
                                            <i class="fas fa-plus"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer" >
                    <button class="btn btn-primary me-auto" data-bs-target="#addProductModal" data-bs-toggle="modal" data-bs-dismiss="modal" v-on:click="startProductList()">
                        <i class="fas fa-plus-circle"></i> Toevoegen
                    </button>
                    <button class="btn btn-danger" data-bs-dismiss="modal" v-if="productionData.productions[productionKey]" v-on:click="cancelProductionStatus()">
                        <i class="fas fa-arrow-left"></i> Annuleren
                    </button>
                    <button class="btn btn-primary" data-bs-dismiss="modal" v-if="productionData.productions[productionKey]" v-on:click="saveProduction(productionData.productions[productionKey].number)">
                        <i class="fas fa-save"></i> Opslaan
                    </button>
                </div>
            </div>
        </div>
    </div>

<!-- MODAL addProductModal   -->
    <div class="modal fade" id="addProductModal" aria-hidden="true" aria-labelledby="addProductModal" tabindex="-1">
        <div class="modal-dialog modal-xl modal-dialog-centered">
            <div class="modal-content" v-if="renderProductModal">
                <div class="modal-header">
                    <select class="form-select" aria-label=" example" v-model="productFilterCategory">
                        <option value="0">Veel gebruikt</option>
                            <option v-for="categorie of categorieData.categories" :key="categorie.category_id" v-bind:value="categorie.category_id">
                                <span >{{categorie.title}}</span>  
                            </option>
                    </select>
                        <div class="input-group ms-2">
                            <input type="text" class="form-control" placeholder="Zoeken..." v-model="productSearchFilter">
                            <!-- <button class="btn btn-success" type="submit">Zoek</button> -->
                        </div>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="container-fluid overflow-auto" style="max-height: 60vh; min-height: 60vh;">
                        <div class="row" v-if="productsData.products">
                            <div class="col-12 col-lg-6 col-xl-4 col-xxl-3  ps-0 py-3" v-for="aProduct in filteredProducts" :key="aProduct.product_id">
                                <div class="border bg-light p-md-3 h-100 boxscreen-item">
                                    <div class="item-title mb-3">
                                        <h6 class="d-inline-block mb-0" v-html="aProduct.title"></h6>
                                        <h6 class="text-muted" v-html="aProduct.sku"></h6>
                                    </div>
                                    <div class="item-img mt-0 d-none d-lg-inline">                        
                                        <img v-bind:src="aProduct.image" alt="Product afbeelding" class="responsive-fluid w-100" />
                                    </div>
                                    <div class="mt-3" >
                                        <button type="button" v-if="!currentProductions[productionKey].source_products['p' + aProduct.product_id]" class="btn btn-success w-100" v-on:click="addProductToSourceProducts(aProduct.product_id)"  data-bs-target="#productionModal" data-bs-toggle="modal" data-bs-dismiss="modal"><i class="fas fa-plus"></i> Toevoegen</button>
                                        <button type="button" v-if="currentProductions[productionKey].source_products['p' + aProduct.product_id]" class="btn btn-warning w-100"><i class="fas fa-min"></i> Toegevoegd</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-primary" data-bs-target="#productionModal" data-bs-toggle="modal" data-bs-dismiss="modal">
                        <i class="fas fa-arrow-left"></i> Terug
                    </button>
                </div>
            </div>
        </div>
    </div>

<!-- MODAL Filters   -->
   <div class="modal fade " id="filterModal" tabindex="-1" aria-labelledby="filterModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="filterModalLabel">Filter toevoegen</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="container">
                    <div class="row">
                        <h3>Status:</h3>
                        <div class="card col-12 col-md-3 m-2 text-center" >
                            <div class="card-body">
                                <h5 class="card-title">Nieuw</h5>
                                <div class="form-check form-switch form-switch-xl d-flex justify-content-center">
                                    <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" value="1" v-model="productionFilter.filterStatus.statusNew">
                                </div>
                            </div>
                        </div>

                        <div class="card col-12 col-md-3 m-2 text-center" >
                            <div class="card-body">
                                <h5 class="card-title">In productie</h5>
                                <div class="form-check form-switch form-switch-xl d-flex justify-content-center">
                                    <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" value="1" v-model="productionFilter.filterStatus.statusInProduction">
                                </div>
                            </div>
                        </div>

                        <div class="card col-12 col-md-3 m-2 text-center" >
                            <div class="card-body">
                                <h5 class="card-title">Gereed</h5>
                                <div class="form-check form-switch form-switch-xl d-flex justify-content-center">
                                    <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" value="1" v-model="productionFilter.filterStatus.statusComplete">
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="row mt-2">
                        <h3>Prioriteit:</h3>
                        <div class="card col-12 col-md-3 m-2 text-center" >
                            <div class="card-body">
                                <h5 class="card-title">Hoog</h5>
                                <div class="form-check form-switch form-switch-xl d-flex justify-content-center">
                                    <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" value="1" v-model="productionFilter.filterPriority.priorityHigh">
                                </div>
                            </div>
                        </div>
                        
                        <div class="card col-12 col-md-3 p-2 m-2 text-center" >
                            <div class="card-body">
                                <h5 class="card-title">Standaard</h5>
                                <div class="form-check form-switch form-switch-xl d-flex justify-content-center">
                                    <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" value="1" v-model="productionFilter.filterPriority.priorityDefault">
                                </div>
                            </div>
                        </div>

                        <div class="card col-12 col-md-3 p-0 m-2 text-center">
                            <div class="card-body">
                                <h5 class="card-title">Laag</h5>
                               <div class="form-check form-switch form-switch-xl d-flex justify-content-center">
                                    <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" value="1" v-model="productionFilter.filterPriority.priorityLow">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>

</div>

</template>

<script>
// import axios from 'axios'
export default{
    name: "production-screen",
    data(){
        return {
            productionData: {
                productions: [],
                from_cache: '',
            },
            currentProductions: {},
            categorieData: {
                categories: [],
                from_cache: '',
            },
            productsData: {
                products: [],
                from_cache: '',
            },
            productionFilter: {
                filterType: 1, // 1 = assemblage, 2 = zagen, 3 = demontage
                filterStatus: {
                    statusNew: 1,
                    statusInProduction: 0,
                    statusComplete: 0,
                },
                filterPriority: {
                    priorityHigh: 0,
                    priorityDefault: 1,
                    priorityLow: 0,
                }, 
            },
            sendData: {},                
            productFilterCategory: 0,
            productSearchFilter: '',
            productionKey: null,
            renderComponent: true,
            renderProductModal: false,
            countQueue: 0,
            waitQueue: false,
            timeoutQueue: 1000,
        }
    },
    mounted() {
        if(localStorage.productionFilter){ 
            this.productionFilter = JSON.parse(localStorage.productionFilter); 
        }
        if(localStorage.currentProductions){ 
            this.currentProductions = JSON.parse(localStorage.currentProductions); 
        }
        this.loadProductionData();
        this.loadProductsData();
        this.autoSendQueue();
    },
    watch: {
        productionFilter: {
            handler(newFilterType){
                localStorage.productionFilter = JSON.stringify(newFilterType);
            },
            deep: true
        },

    },
    methods: {
        loadProductionData: function(){
            var tmVersion = new Date().getTime();
            fetch(window.VUE_APP_API_URL +'productions?v=' + tmVersion)
                .then(res => res.json())
                .then(data => {
                    if(data.status == 'success'){
                        this.productionData.productions = data.productions;
                        this.productionData.from_cache = '';
                        localStorage.productionData = JSON.stringify(data);
                    } else {
                        this.loadProductionDataFromCache();
                    }
                })
                .catch(err => { console.log(err); this.loadProductionDataFromCache(); });
        },
        loadProductionDataFromCache: function(){
            if(localStorage.productionData){
                var productionData = JSON.parse(localStorage.productionData);
                if(productionData.status == 'success'){
                    this.productionData.productions = productionData.productions;
                    this.productionData.from_cache = productionData.update_date;
                }
            }
        },
        loadCategoriesData: function(){
            var tmVersion = new Date().getTime();
            fetch(window.VUE_APP_API_URL +'categories?v=' + tmVersion)
                .then(res => res.json())
                .then(data => {
                    if(data.status == 'success'){
                        this.categorieData.categories = data.categories;
                        this.categorieData.from_cache = '';
                        localStorage.categorieData = JSON.stringify(data);
                    } else {
                        this.loadCategorieDataFromCache();
                    }
                })
                .catch(err => { console.log(err); this.loadCategorieDataFromCache(); });
        },
        loadCategorieDataFromCache: function(){
            if(localStorage.categorieData){
                var categorieData = JSON.parse(localStorage.categorieData);
                if(categorieData.status == 'success'){
                    this.categorieData.categories = categorieData.categories;
                    this.categorieData.from_cache = categorieData.update_date;
                }
            }
        },
        loadProductsData: function(){
            var tmVersion = new Date().getTime();
            fetch(window.VUE_APP_API_URL +'products?v=' + tmVersion)
                .then(res => res.json())
                .then(data => {
                    if(data.status == 'success'){
                        this.productsData.products = data.products;
                        this.productsData.from_cache = '';
                        localStorage.productsData = JSON.stringify(data);
                    } else {
                        this.loadProductsDataFromCache();
                    }
                })
                .catch(err => { console.log(err); this.loadProductsDataFromCache(); });
        },
        loadProductsDataFromCache: function(){
            if(localStorage.productsData){
                var productsData = JSON.parse(localStorage.productsData);
                if(productsData.status == 'success'){
                    this.productsData.products = productsData.products;
                    this.productsData.from_cache = productsData.update_date;
                }
            }
        },
        startProductList: function(){
            if(Object.keys(this.categorieData.categories).length == 0){
                this.loadCategoriesData();
            }
            this.renderProductModal = true;
        },
        setproductionKey: function (production_key) {
            this.productionKey = production_key;    
            this.setProductionData(production_key);
        },
        addToQueue:function(type, method, url, data){

            var sendQueue = [];
            if(localStorage.sendQueue){
                sendQueue = JSON.parse(localStorage.sendQueue);
            }
            sendQueue.push({'type': type, 
                            'method' : method, 
                            'url': url,
                            'data': data});

            localStorage.sendQueue = JSON.stringify(sendQueue);
            this.counterQueue(sendQueue);

        },
        setProductionData: function(production_key){
            // if(!this.currentProductions[production_key]){
                let linked_products = {};
                let productionProductId = this.productionData.productions[production_key].product_id;
                console.log("productionProductId -" + productionProductId + "-");
                console.log("productionProductId - linked_products", this.productsData.products['p' + productionProductId].linked_products);
                    if((this.productsData.products) && (this.productsData.products['p' + productionProductId]) && (this.productsData.products['p' + productionProductId].linked_products)){

                        Object.keys(this.productsData.products['p' + productionProductId].linked_products).forEach(key => {
                            const lpProduct = this.productsData.products['p' + productionProductId].linked_products[key];

                            lpProduct.quantity_finish = 0;
                            lpProduct.default = true;
                            linked_products['p' + lpProduct.product_id] = lpProduct;
                            console.log("lProduct -- ", lpProduct);
                        });
                    }
                    
                console.log("linked_products -", linked_products);
                this.currentProductions[production_key] = { 'quantity': parseFloat(this.productionData.productions[production_key].quantity),
                                                            'quantity_finish': parseFloat(this.productionData.productions[production_key].quantity), 
                                                            'product_id': productionProductId,
                                                            'source_products': linked_products };
                this.saveCurrentProductions();
            // }
        },
        startProduction: function(production_key){
            var production_id = this.productionData.productions[production_key].id;
            this.productionData.productions[production_key].production_status = 1;

            this.addToQueue('start_production', 'GET', 'status/'+ production_id + '/1', null);
            this.setProductionData(production_key);
        },
        saveProduction: function (production_key) {
            this.productionData.productions[production_key].production_status = 2;
            let production_id = this.productionData.productions[production_key].id;

            this.addToQueue('save_production', 'POST', 'save/'+ production_id, JSON.stringify(this.currentProductions[production_key]));
        },
        cancelProductionStatus: function () {
            this.productionData.productions[this.productionKey].production_status = 0;
            var production_id = this.productionData.productions[this.productionKey].id;

            this.addToQueue('cancel_production', 'GET', 'status/'+ production_id + '/0', null);
        },
        setProductionType: function (type_value) {
            this.productionFilter.filterType = type_value;
        },
        setProductionQuantityFinish: function(productionKey){
            this.currentProductions[productionKey].quantity_finish = this.currentProductions[productionKey].quantity;
            this.saveCurrentProductions();
            this.forceRerender();
        },
        changeProductionQuantityFinish: function(productionKey, change){
            this.currentProductions[productionKey].quantity_finish = (parseFloat(this.currentProductions[productionKey].quantity_finish) + parseFloat(change));
            this.saveCurrentProductions();
            this.forceRerender();
        },
        changeSourceQuantityFinish: function(sProduct, change){
            sProduct.quantity_finish = (parseFloat(sProduct.quantity_finish) + parseFloat(change));
            this.saveCurrentProductions();
            this.forceRerender();
        },
        setSourceQuantityFinish: function(sProduct){
            sProduct.quantity_finish = (sProduct.quantity * this.currentProductions[this.productionKey].quantity_finish);
            this.saveCurrentProductions();
            this.forceRerender();
        },
        addProductToSourceProducts: function(product_id){
            this.currentProductions[this.productionKey].source_products["p" + product_id] = {'product_id': product_id,
                                                                                             'default': false,
                                                                                             'quantity': 0,
                                                                                             'quantity_finish': 0};
            this.saveCurrentProductions();
            this.forceRerender();
        },
        saveCurrentProductions: function(){
            localStorage.currentProductions = JSON.stringify(this.currentProductions);
        },
        autoSendQueue: function(){
            this.startSendQueue();
            setTimeout(function(scope){
                scope.autoSendQueue();
            }, this.timeoutQueue, this);
        },
        startSendQueue: function(){
            console.log("queue controleren....");
            var sendQueue = [];
            if((localStorage.sendQueue) && (!this.waitQueue)){
                sendQueue = JSON.parse(localStorage.sendQueue);

                if(sendQueue.length >= 1){
                    this.waitQueue = true;
                    var sendAction = sendQueue[Object.keys(sendQueue)[0]];
                    console.log("sendAction", sendAction);
                    console.log("queue verzenden....");
                    
                    fetch(window.VUE_APP_API_URL + sendAction.url, {
                            method: sendAction.method,
                            body: sendAction.data,
                            headers: {}
                        })
                        .then((response) => response.json())
                        .then((json) => {
                            sendQueue = JSON.parse(localStorage.sendQueue);
                            var index = sendQueue.indexOf(sendAction);
                            if(index){
                                sendQueue.splice(index, 1);
                                localStorage.sendQueue = JSON.stringify(sendQueue);
                                this.counterQueue(sendQueue);
                            }
                            console.log("Verwerkt... ", json);
                            this.waitQueue = false;
                            this.timeoutQueue = 1000;
                        })
                        .catch((error) => { 
                            console.log('Verwerken mislukt... ', error);
                            this.waitQueue = false;
                            this.timeoutQueue = 30000;
                        });
                }
            }
        },
        counterQueue: function(sendQueue){
            this.countQueue = sendQueue.length;
        },
        forceRerender() {
            // Remove my-component from the DOM
            this.renderComponent = false;

            this.$nextTick(() => {
                // Add the component back in
                this.renderComponent = true;
            });
        },
    },
    computed: {
      filterProductions: function () {
          if(Object.keys(this.productionData.productions).length >= 1){

              let filteredProductions = {};

                Object.keys(this.productionData.productions).forEach(productionKey => {
                    let production = this.productionData.productions[productionKey];
                    if(production.production_type == this.productionFilter.filterType){
                        if(((production.production_status == 0) && (this.productionFilter.filterStatus.statusNew == 1)) ||
                            ((production.production_status == 1) && (this.productionFilter.filterStatus.statusInProduction == 1)) ||
                            ((production.production_status == 2) && (this.productionFilter.filterStatus.statusComplete== 1))) {

                            if(((production.production_priority == 1) && (this.productionFilter.filterPriority.priorityHigh == 1)) ||
                            ((production.production_priority == 2) && (this.productionFilter.filterPriority.priorityDefault == 1)) ||
                            ((production.production_priority == 3) && (this.productionFilter.filterPriority.priorityLow== 1))){
                                
                                filteredProductions[productionKey] = production;
                            }
                        }
                    }
                });

            return filteredProductions;

          }
          return {};
      },
      filteredProducts: function () {
          if((this.productsData.products) && (Object.keys(this.productsData.products).length >= 1)){
            let filteredProducts = {}; var iAddProducts = 0;
                Object.keys(this.productsData.products).forEach(productKey => {
                    let product = this.productsData.products[productKey];
                    if(((product.category_id == this.productFilterCategory) && ((product.title.toUpperCase().includes(this.productSearchFilter.toUpperCase())) || (this.productSearchFilter == ''))) 
                           ||
                       (((product.category_id == this.productFilterCategory) || (this.productFilterCategory == '') || (this.productFilterCategory == '0')) && 
                        (this.productSearchFilter != '') && 
                        ((product.title.toUpperCase().includes(this.productSearchFilter.toUpperCase())) || (product.sku.toUpperCase().includes(this.productSearchFilter.toUpperCase()))))){ 

                            
                            // Max 10 results
                            if(iAddProducts < 12){
                                filteredProducts[productKey] = product;
                            }
                            iAddProducts++;
                    }
                });
            
            return filteredProducts;
          }
          return {};
      },
    }
};

</script>

<style lang="scss">
$primary: #3490dc;
$primaryl: #5aa6e4;
$secondary2: #dee2e6;
 
.topmenu {
    position: relative;
    top:-160px;
    transition: top 2s ease 0s;
    left:0;
    width: 100%;
    max-width: 100vw;
    z-index:500;
   
    .menuinner {
        height:160px;
        background-color:$primaryl;
        border-bottom:solid $primary 10px;
        padding:20px;

        .container-fluid {
            overflow-y: hidden;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;

            &::-webkit-scrollbar {
                height:22px;
                background-color: $primaryl;
            }
        }
        .menulogo {
            max-height: 100px;
            margin-right: 20px;
        }
        .menuitem {
            border:solid #e2e2e2 1px;    
            padding: 27px 10px;
            line-height: 1;
            font-size: 16px;
            width:136px;
            text-align: center;
            display: inline-block;
            color:#fff;
            margin-top:4px;
            text-decoration: none;

            i { 
                font-size: 24px; 
                height: 24px; 
                margin-bottom:10px; 
                display: block; 
            }

            &.active,
            &:hover {
                background-color: $primary;
                border-color: $primary;
            }
        }
    }
    &.open {
        top:0;
    }
}
@media (max-width: 560px){
    .topmenu {
       .menutoggle {
           text-align: right;
            i {
              border-bottom-right-radius:0;
            }
       }
    }
}
 
.btn-square-md{
    width: 75px;
    height: 75px; 
}

.form-switch.form-switch-xl .form-check-input {
  height: 2.5rem;
  width: calc(4rem + 0.75rem);
  border-radius: 5rem;
}

.item-count-nav {
    button {
        width: 48px;
        height: 48px;
        line-height: 1;
        background-color: #dae0e5;
        border-color: #d3d9df;
        padding: 0;
        font-size: 25px;
        display: inline-block;
        vertical-align: top;
    }
    input {
        width:5em;
        display: inline-block;
        text-align: center;
    }
}

.boxscreen-item {
    border-width: 3px!important;

    .item-title {
        overflow: hidden;
    }
    .item-img {
        position: relative;
        max-width:220px;
        max-height: 220px;
        button {
            position: absolute;
            top:50%;
            text-align: center;
            left:0;
            width:100%;
            height:48px;
            font-size: 48px;
            line-height: 1;
            padding: 20px 0;
            margin-top:-44px;
            z-index: 100;
            background-color: transparent;
            border: none;
            outline: none;
        }
    }
    .item-count {
        button {
            width: 48px;
            height: 48px;
            line-height: 1;
            background-color: #dae0e5;
            border-color: #d3d9df;
            padding: 0;
            font-size: 25px;
            display: inline-block;
            vertical-align: top;
        }
        input {
            width:calc(100% - 96px);
            display: inline-block;
            text-align: center;
            -moz-appearance: textfield;
            -webkit-appearance: none;
        }
    }
}

.productionline {
    font-size: 20px;
}
.queue_counter {
    position:relative;
}
    .queue_counter .badge {
        position:absolute;
        top:3px;
        right:3px;
        
    }

/* Chrome, Safari, Edge, Opera */
input.quantity-input::-webkit-outer-spin-button,
input.quantity-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.quantity-input[type=number] {
  -moz-appearance: textfield;
}

</style>